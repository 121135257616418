
import React, { useState, useEffect } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';

import './Work.scss';

const Work = () => {

  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);

  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query)
      .then((data) => {
        setWorks(data);
        setFilterWork(data);
      })
  }, [])
  
  //creative works filter 
  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{y:100, opacity: 0}]);

    setTimeout(() => {
      setAnimateCard([{ y:0, opacity: 1}]);

      if(item === 'All') {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  }

  return (
    <>
    <h2 className="head-text">My <span>Portfolio</span></h2>

    <motion.div
    whileHover={{scale: [1, 1.04]}} 
    transition={{ duration: 0.5}}
    className="app__work-filter">
      
      {[ 'UI/UX', 'Websites', 'Web Apps', 'Figma', 'Games', 'Apps', 'School Work', 'Python', 'Javascript', 'Design', 'All'].map ((item, index) => (
        <div
        key={index}
        onClick={() => handleWorkFilter(item)}
        className={`app__work-filter-item app__flex p-text ${activeFilter ===  item ? 'item-active' : ''}`}
        >
          {item}
        </div>


      ))}
    </motion.div>

    <motion.div
    
    animate={animateCard}
    transition={{ duration: 0.5, delayChildren: 0.5 }}
    className="app__work-portfolio"
    >
      {filterWork.map((work, index) => (
        <div className="app__work-item app__flex" key={index}>
         <div className ="app__work-img app__flex">
          <img src={urlFor(work.imgUrl)} 
          alt={work.name} 
          />

          <motion.div
            whileHover={{opacity: [0, 1]}}
            transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
            className="app__work-hover"
          >
            <p className="bold-text" style={{ marginTop:10, marginLeft: 10, marginRight: 10, marginBottom: 10 }}>{work.description}</p>
          </motion.div>

          </div>

          <div className="app__work-content app__flex">

            <h4 className="bold-text">{work.title}</h4>
            
            

          </div>

          

            <a href={work.codeLink} target="_blank" rel="noreferrer">
              <motion.div
                whileinView={{scale: [0, 1]}}
                whileHover={{ scale: [1, 0.9]}}
                transition={{ duration: 0.1 }}
                className="app__icons"
              >
                <AiFillEye />
              </motion.div>
            </a>
            
        </div>
      ))}
    </motion.div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Work, 'app__works'),
  'Portfolio',
  'app__primarybg'
  );