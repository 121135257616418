
import React from 'react'

const NavigationDots = ({ active }) => {
  return (
    <div className= "app__navigation">
          

    </div>
  )
}

export default NavigationDots
// include 'testimonials' if you want to add dot for 'testimonials'

//include in div for navigation dots
/*
{['Home', 'About Me', 'Portfolio', 'Skills', 'Contact'].map((item, index) => (
            <a 
            href={`#${item}`}
            key={item + index}
            className= "app__navigation-dot"
            style={active === item ? {backgroundColor: '#FFFFFF'} : { }}
            />
            ))}
*/
