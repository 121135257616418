
import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';

import { images } from '../../constants';

import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0,1],
    opacity: [0,1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {
  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, -20], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
        >
          <div className="app__header-badge">
            <div className="badge-cmp app__flex">
            </div>
          </div>
        </motion.div>

        <motion.div // pre pracu v celku, cely Div bude affected
          whileInView={{ opacity: [0, 1] }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.5, delayChildren: 0.5 }}
          
          className="app__header-img"
        >
          <motion.img 
          whileHover={{ scale: 1.1 }}
          src={images.profile} 
          alt="profile_bg" 
          />
            <motion.img
              whileInView={{ scale: [0, 1] }}
              transition={{ duration: 1, ease: 'easeInOut' }}
              src={images.circle}
              alt="profile_circle"
              className="overlay_circle"
            />
            </motion.div>

            <motion.div
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.2}}
              className="app__header-circles"
            >
                  <div style={{ marginLeft: 20}}>
                    <p className="head-text">Hello, I am Filip!</p>
                    <p className="p-text">I am a self-taught web developer with experience</p>
                    <p className="p-text">in both frontend and backend development.</p>
                    <p className="p-text">I have a passion for automating tasks</p>
                    <p className="p-text">and making websites more efficient.</p>
                  </div>
            </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'Home');

/*
<span> 👋 </span>
              <div style={{ marginLeft: 20}}>
                <p className="p-text">Hello, I am</p>
                <h1 className="head-text">Filip</h1>
              </div>

{[images.bubble].map((circle, index) => (
                <div className="circle-cmp app__flex" key={`circle-${index}`}>
                  <img src={circle} alt ="circle"/>
                </div>

<div style={{ marginLeft: 20}}>
                    <p className="head-text">Hello, I am Filip!</p>
                    <p className="p-text">I am a self-taught web developer with experience</p>
                    <p className="p-text">in both frontend and backend development.</p>
                    <p className="p-text">I have a passion for automating tasks</p>
                    <p className="p-text">and making websites more efficient.</p>
                  </div>
        

*/